import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useUserAuth } from "../../../context/UserAuthContext";

const Profile = () => {
  const { user, updateDisplayName, changePassword } = useUserAuth();
  const [displayName, setDisplayName] = useState(user.displayName || "");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleDisplayNameChange = async () => {
    try {
      await updateDisplayName(displayName);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      console.log("Passwords do not match");
      return;
    }
    try {
      await changePassword(newPassword);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="box mt-3">
      <h4>Personal information</h4>{" "}
      <Form className="mt-3">
        <Form.Group>
          <Form.Label>Display Name</Form.Label>
          <Form.Control
            type="text"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
          <Button className="mt-1" onClick={handleDisplayNameChange}>
            Change Display Name
          </Button>
        </Form.Group>
        <Form.Group className="mt-10">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <Form.Label className="mt-3">Confirm New Password</Form.Label>
          <Form.Control
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button className="mt-1" onClick={handlePasswordChange}>
            Change Password
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

export default Profile;
