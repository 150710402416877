import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert, Button, Image, Modal } from "react-bootstrap";
import { useUserAuth } from "../../../context/UserAuthContext";
import { LogIn } from "react-feather";
import googleIcon from "assets/images/png/google.png";
import appleIcon from "assets/images/brand/apple.svg";
import microsoftIcon from "assets/images/brand/microsoft.svg";

const SignInModal = ({ show, handleClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { user, logIn, signInWithGoogle, signInWithMicrosoft } = useUserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      handleClose();
    }
  }, [user, navigate, handleClose]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await logIn(email, password);
      handleClose();
    } catch (err) {
      setError("Invalid email or password. Please try again.");
    }
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithGoogle();

      handleClose();
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleMicrosoftSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithMicrosoft();
      handleClose();
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered className="rounded">
      <Modal.Header closeButton className="border-0 text-center">
        <Modal.Title>Sign In</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder="Email address"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <div className="d-grid gap-2">
            <Button variant="info" type="Submit">
              <LogIn size={20} className="me-2" />
              Sign In
            </Button>
          </div>
        </Form>
        <hr />
        <div>
          <Button
            variant="social-outline"
            className="w-100"
            onClick={handleGoogleSignIn}
          >
            <Image
              src={googleIcon}
              alt="Google"
              width="24"
              height="24"
              className="me-2"
            />
            Sign up with Google
          </Button>
          {/* <Button
            variant="social-outline"
            className="w-100 mt-2"
            onClick={handleGoogleSignIn}
          >
            <Image
              src={appleIcon}
              alt="Apple"
              width="24"
              height="24"
              className="me-2"
            />
            Sign up with Apple
          </Button> */}
        </div>
        <div className="text-center mt-3">
          Don't have an account?{" "}
          <Link to="/signup" onClick={handleClose}>
            Sign up
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SignInModal;
