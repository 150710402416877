import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { useUserAuth } from "../../../context/UserAuthContext";

const useUserData = () => {
  const { user } = useUserAuth();
  const [subscription, setSubscription] = useState([]);
  const [notificationPreferences, setNotificationPreferences] = useState({
    email: false,
    text: false,
    push: false,
    filings: {
      "8-K": false,
      "10-K": false,
      "10-Q": false,
      4: false,
      "SC 13D": false,
      "SC 13G": false,
    },
  });
  const [fcmToken, setFcmToken] = useState("");

  useEffect(() => {
    const getUserDoc = async () => {
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);
      const userDoc = userDocSnap.data();

      if (userDoc && userDoc.subscription) {
        setSubscription(userDoc.subscription);
      }

      if (userDoc && userDoc.notificationPreferences) {
        setNotificationPreferences({
          email: false,
          text: false,
          push: false,
          filings: {
            "8-K": false,
            "10-K": false,
            "10-Q": false,
            4: false,
            "SC 13D": false,
            "SC 13G": false,
          },
          ...userDoc.notificationPreferences,
          filings: {
            ...notificationPreferences.filings,
            ...userDoc.notificationPreferences.filings,
          },
        });
      }

      if (userDoc && userDoc.fcmToken) {
        setFcmToken(userDoc.fcmToken);
      }
    };

    getUserDoc();
  }, [user]);

  return {
    subscription,
    notificationPreferences,
    setNotificationPreferences,
    fcmToken,
    setFcmToken,
  };
};

export default useUserData;
