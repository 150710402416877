// import node module libraries
import {
	Container,
	Row,
	Col,
	Table,
	Badge,
	Button,
	Form,
	Image,
	Tooltip,
	OverlayTrigger,
} from "react-bootstrap";

import { getDocs, query, collection, where } from "firebase/firestore";

// import MDI icons
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
	getDoc,
	doc,
	Timestamp,
	updateDoc,
	arrayUnion,
	arrayRemove,
} from "firebase/firestore";

import { db } from "../../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useUserAuth } from "../../context/UserAuthContext";
import logoPlaceholder from "../../assets/images/png/logo-placeholder.png";

const Company = () => {
	const functions = getFunctions();
	const [selectedCompany, setSelectedCompany] = useState(true);
	const { user } = useUserAuth();
	const { companyId } = useParams();
	//   companyId is a CIK number
	const [company, setCompany] = useState({});
	const [, setCompanyDetails] = useState({});
	const [filings, setFilings] = useState([]);
	const [processedFilings, setProcessedFilings] = useState([]);

	const removeLeadingZeros = (str) => {
		str = str.toString();
		return str.replace(/^0+/, "");
	};

	const allFilingTypes = [
		"10-K",
		"10-Q",
		"8-K",
		"4",
		"SC 13D",
		"SC 13G",
		"SC 13D/A",
		"SC 13G/A",
	];

	//   make a request to tde backend to get the filings for this company
	useEffect(() => {
		// get the company from firestore
		const getCompany = async () => {
			try {
				const companyRef = doc(db, "companies", companyId);
				const companyDoc = await getDoc(companyRef);
				setCompany(companyDoc.data());
				// if theres no industry field, call the getCompany function from the backend
				if (!companyDoc.data().industry) {
					const getCompany = httpsCallable(functions, "getCompany");
					const result = await getCompany({ companyId: companyId });
					setCompany(result.data);
				}
			} catch (err) {
				console.log(err);
			}
		};

		const getFilings = async () => {
			try {
				const res = await axios.get(
					`https://data.sec.gov/submissions/CIK${companyId}.json`
				);

				setCompanyDetails({
					tickers: res.data.tickers,
					exchanges: res.data.exchanges,
					description: res.data.sicDescription,
				});
				// res.data.filings.recent has several keys with array values: accessionNumber, form, filingDate, primaryDocument
				// the values in each array are in order and correspond to the same filing
				// create an object for each accessionNumber and add it to the filings array

				const recentFilings = [];
				const accessionNumbers = [];

				for (
					let i = 0;
					i < res.data.filings.recent.accessionNumber.length;
					i++
				) {
					if (
						allFilingTypes.includes(res.data.filings.recent.form[i])
					) {
						const updatedDate =
							res.data.filings.recent.acceptanceDateTime[i];
						const date = new Date(updatedDate);
						const updated = Timestamp.fromDate(date);

						recentFilings.push({
							accessionNumber:
								res.data.filings.recent.accessionNumber[i],
							form: res.data.filings.recent.form[i],
							filingDate: res.data.filings.recent.filingDate[i],
							primaryDocument:
								res.data.filings.recent.primaryDocument[i],
							acceptanceDateTime:
								res.data.filings.recent.acceptanceDateTime[i],
							updated,
						});

						accessionNumbers.push(
							res.data.filings.recent.accessionNumber[i]
						);
					}
				}

				// get all filing documents with IDs in accessionNumbers using a where clause and getDocs
				const chunk = (arr, size) =>
					Array.from(
						{ length: Math.ceil(arr.length / size) },
						(v, i) => arr.slice(i * size, i * size + size)
					);

				const chunks = chunk(accessionNumbers, 30);
				let resultFilings,
					dbFilings = [];

				for (let i = 0; i < chunks.length; i++) {
					dbFilings = await getDocs(
						query(
							collection(db, "filings"),
							where("accessionNumber", "in", chunks[i])
						)
					);

					// add all results to the processedFilings array
					resultFilings = dbFilings.docs.map((doc) => ({
						...doc.data(),
					}));

					setProcessedFilings((prev) => [...prev, ...resultFilings]);
				}

				setFilings(recentFilings);
			} catch (err) {
				console.log(err);
			}
		};

		const getUserDoc = async () => {
			if (!user) return;
			const userDocRef = doc(db, "users", user.uid);
			const userDocSnap = await getDoc(userDocRef);
			const userDoc = userDocSnap.data();

			// if the user has a listeners array, set the selectedCompany state to true if the listeners array includes the companyId
			if (userDoc.listeners && userDoc.listeners.includes(companyId)) {
				setSelectedCompany(true);
			}
		};

		getUserDoc();
		getCompany();
		getFilings();
	}, [companyId, user]);

	// use allFilingTypes to create a filters object with all filing types as keys and true as values
	const [filters, setFilters] = useState(
		allFilingTypes.reduce((acc, form) => {
			acc[form] = true;
			return acc;
		}, {})
	);

	const handleFilterChange = (formType) => {
		setFilters({
			...filters,
			[formType]: !filters[formType],
		});
	};

	const handleButtonClick = async (cik) => {
		if (!user) return;
		const userDocRef = doc(db, "users", user.uid);
		if (selectedCompany) {
			setSelectedCompany(false);
			// Update the user document to remove the listener
			try {
				await updateDoc(userDocRef, {
					listeners: arrayRemove(cik),
				});
			} catch (error) {
				console.error(error); // handle the error if needed
			}
		} else {
			setSelectedCompany(true);
			// Update the user document to add the listener
			try {
				await updateDoc(userDocRef, {
					listeners: arrayUnion(cik),
				});
			} catch (error) {
				console.error(error); // handle the error if needed
			}
		}
	};

	return (
		<Container>
			<Row className="mt-6 mb-3">
				<Col className="d-flex justify-content-between align-items-start">
					<div>
						<h1 className="d-flex align-items-center">
							<Image
								src={company.logo || logoPlaceholder}
								alt={company.name}
								style={{ width: "5rem", height: "5rem" }}
								className="me-3"
								onError={(e) => {
									e.target.onerror = null;
									e.target.src = logoPlaceholder;
								}}
							/>{" "}
							{company.name}
						</h1>
						{/* no borders */}
						<div className="mb-3">
							<Badge
								bg="light-success text-dark"
								className="rounded-1 ms-1"
							>
								{company.exchange}
							</Badge>
							- <strong>{company.symbol}</strong> |
							{/* label info */}
							<Badge
								bg="light-info text-dark"
								className="rounded-1 ms-1"
							>
								{company.industry}
							</Badge>{" "}
							|{" "}
							<a href={company.website} target="_blank">
								Website <i className="fe fe-external-link"></i>
							</a>
						</div>

						<div className="mb-3">
							<strong>Business Summary:</strong>{" "}
							<small>{company.longBusinessSummary}</small>
						</div>
					</div>
					{/* heart button to save company to user's favorites */}
					<OverlayTrigger
						overlay={
							<Tooltip>
								{selectedCompany ? "Unsubscribe" : "Subscribe"}
							</Tooltip>
						}
					>
						<Button
							className={
								selectedCompany
									? "btn btn-success btn-sm"
									: "btn btn-light btn-sm"
							}
							onClick={(event) => {
								event.preventDefault();
								handleButtonClick(company.cik);
							}}
						>
							{selectedCompany ? (
								<i className="fe fe-check"></i>
							) : (
								<i className="fe fe-plus"></i>
							)}
						</Button>
					</OverlayTrigger>
				</Col>
			</Row>
			<Row>
				<Col>
					<h3 className="mb-3">Filings</h3>
					<Form>
						<Form.Group>
							{/* create a for loop over filters and create a checkbox for each */}
							{Object.keys(filters).map((formType) => {
								return (
									<Form.Check
										key={formType}
										inline
										type="checkbox"
										label={formType}
										checked={filters[formType]}
										onChange={() =>
											handleFilterChange(formType)
										}
									/>
								);
							})}
						</Form.Group>
					</Form>
					<Table responsive>
						<thead>
							<tr>
								<th>Form</th>
								<th>Accession Number</th>
								<th>Analysis</th>
								<th>Filing Date</th>
								<th>Official Document</th>
								{user && user.role !== "admin" ? null : (
									<th>Actions</th>
								)}
							</tr>
						</thead>
						<tbody>
							{filings
								.filter((filing) => filters[filing.form])
								.map((filing) => (
									<tr key={filing.accessionNumber}>
										<td>
											<Badge
												bg={
													filing.form === "10-K"
														? "warning"
														: filing.form === "10-Q"
															? "info"
															: "danger"
												}
												className="rounded-1"
											>
												{filing.form}
											</Badge>
										</td>
										<td>{filing.accessionNumber}</td>
										<td>
											<Link
												to={`/filings/${filing.accessionNumber}`}
											>
												View Analysis
											</Link>
										</td>
										<td>
											{filing.updated
												?.toDate()
												.toLocaleString()}
										</td>
										<td>
											{company.cik && (
												<a
													target="_blank"
													href={`https://www.sec.gov/Archives/edgar/data/${removeLeadingZeros(
														company.cik
													)}/${filing.accessionNumber.replaceAll("-", "")}/${
														filing.primaryDocument
													}`}
												>
													View document
												</a>
											)}
										</td>
										<td>
											{processedFilings.some(
												(f) =>
													f.accessionNumber ===
													filing.accessionNumber
											) ? (
												<Badge
													bg="light text-dark"
													className="rounded-1"
												>
													<i className="fe fe-check"></i>
												</Badge>
											) : (
												user &&
												user.role === "admin" && (
													<Button
														variant="secondary"
														className="btn-sm"
														onClick={async () => {
															const generateFilingAnalysis =
																httpsCallable(
																	functions,
																	"generateFilingAnalysis"
																);
															try {
																generateFilingAnalysis(
																	{
																		filingId:
																			filing.accessionNumber,
																		cikNumber:
																			company.cik,
																	}
																);

																// add the filing with accession number to processedFilings
																setProcessedFilings(
																	[
																		...processedFilings,
																		{
																			accessionNumber:
																				filing.accessionNumber,
																		},
																	]
																);
															} catch (err) {
																console.log(
																	err
																);
															}
														}}
													>
														Generate
													</Button>
												)
											)}
										</td>
									</tr>
								))}
						</tbody>
					</Table>
				</Col>
			</Row>
		</Container>
	);
};

export default Company;
