import React, { useState, useEffect } from "react";
import { Button, Card, Col, Nav, Row } from "react-bootstrap";
import axios from "axios";
import RightColumn from "./partials/RightColumn";
import { db } from "../../firebase";

const Notifications = ({ userId }) => {
  const [activeTab, setActiveTab] = useState("all");
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {}, [userId]);

  const allNotifications = notifications;
  const unreadNotifications = notifications.filter(
    (notification) => !notification.read
  );

  return (
    <Row>
      <Col md={8} sm={12}>
        <Card className="sticky-col transparent-blur-card">
          <Nav
            className="nav-lb-tab nav justify-content-center d-flex"
            role="tablist"
          >
            <Nav.Item className="flex-fill">
              <Nav.Link
                role="tab"
                className={`mb-sm-3 mb-md-0 nav-link text-center ${activeTab === "all" ? "active" : ""}`}
                tabIndex="0"
                onClick={() => setActiveTab("all")}
              >
                All
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="flex-fill">
              <Nav.Link
                role="tab"
                className={`mb-sm-3 mb-md-0 nav-link text-center ${activeTab === "unread" ? "active" : ""}`}
                tabIndex="0"
                onClick={() => setActiveTab("unread")}
              >
                Unread
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Card>
        {activeTab === "all" && (
          <div>
            {allNotifications.map((notification) => (
              <Card key={notification.id} className="mb-2">
                <Card.Body>{notification.message}</Card.Body>
              </Card>
            ))}
          </div>
        )}
        {activeTab === "unread" && (
          <div>
            {unreadNotifications.map((notification) => (
              <Card key={notification.id} className="mb-2">
                <Card.Body>{notification.message}</Card.Body>
              </Card>
            ))}
          </div>
        )}
      </Col>
      <Col md={4} sm={12}>
        <RightColumn />
      </Col>
    </Row>
  );
};

export default Notifications;
