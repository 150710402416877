// src/config/routes.js
import Login from "../components/views/Login";
import Signup from "../components/views/Signup";
import Account from "../components/views/Account";
import Home from "../components/views/Home";
import Notifications from "../components/views/Notifications";
import Stories from "../components/views/Stories";
import Pricing from "../components/views/Pricing";
import BetaSignUp from "../components/views/BetaSignUp";
import Calendar from "../components/views/Calendar";
import Company from "../components/views/Company";
import Filing from "../components/views/Filing";
import Subscribe from "../components/views/Subscribe";
import Search from "../components/views/Search";
import Industries from "../components/views/Industries";
import Sectors from "../components/views/Sectors";
import Wizard from "../components/views/Wizard";
import PurchaseConfirmed from "../components/views/PurchaseConfirmed";

const routes = [
  { path: "/login", title: "Login", Component: Login, hideSidebar: true },
  { path: "/signup", title: "Signup", Component: Signup, hideSidebar: true },
  { path: "/stories", title: "Stories", Component: Stories },
  { path: "/pricing", title: "Pricing", Component: Pricing, hideSidebar: true },
  { path: "/beta", title: "Beta Signup", Component: BetaSignUp },
  { path: "/companies/:companyId", title: "Company", Component: Company },
  { path: "/calendar", title: "Calendar", Component: Calendar },
  { path: "/subscribe", title: "Subscribe", Component: Subscribe },
  { path: "/filings/:filingId", title: "Filing", Component: Filing },
  {
    path: "/notifications",
    title: "Notifications",
    Component: Notifications,
    isProtected: true,
  },
  {
    path: "/industries",
    title: "Industries",
    Component: Industries,
    isProtected: true,
  },
  {
    path: "/sectors",
    title: "Sectors",
    Component: Sectors,
    isProtected: true,
  },
  {
    path: "/purchase-confirmed",
    title: "Purchase Confirmed",
    Component: PurchaseConfirmed,
  },
  {
    path: "/account/:tab?",
    title: "Account",
    Component: Account,
    isProtected: true,
  },
  {
    path: "/wizard",
    title: "Wizard",
    Component: Wizard,
    isProtected: true,
    hideSidebar: true,
  },
  { path: "/search", title: "Search", Component: Search, isProtected: true },
  { path: "/", Component: Home },
];

export const getRoute = (title) => {
  return routes.find((route) => route.title === title);
};

export default routes;
