import React from "react";
import TimeAgo from "react-timeago";
import englishStrings from "react-timeago/lib/language-strings/en";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import { Link } from "react-router-dom";

const formatter = buildFormatter({
  ...englishStrings,
  second: "just now",
  seconds: "%ds",
});

const getBgClass = (type) => {
  if (type === "10-K" || type === "10-K/A") {
    return "bg-warning";
  } else if (type === "10-Q" || type === "10-Q/A") {
    return "bg-success";
  } else if (
    type === "4" ||
    type === "SC 13D" ||
    type === "SC 13G" ||
    type === "SC 13D/A" ||
    type === "SC 13G/A"
  ) {
    return "bg-info"; // Change this to the desired class
  } else {
    return "bg-danger";
  }
};

const FilingListItem = ({ filing, updatedAtDate, today, yesterday }) => {
  // Only render the filing if it was created today or yesterday
  return (
    <div className="d-flex">
      <div
        className={`avatar avatar-initials text-white ${getBgClass(filing.type)} h5 filing-box`}
      >
        {filing.type}
      </div>
      <Link to={`/filings/${filing.id}`} className="ms-3">
        {/* badge for weight of filing - positioned to the right */}
        <h5 className="lh-1 d-flex justify-content-between">
          <span>
            {filing.company?.tradeName}{" "}
            <span className="text-muted">@{filing.company?.symbol}</span>
            <small className="text-body ms-1">
              {" "}
              &middot;{" "}
              <TimeAgo
                className="ms-1"
                date={filing.updatedAt?.toDate()}
                formatter={formatter}
                minPeriod={1} // Update every second
              />
            </small>
          </span>
        </h5>
        {/* headline summary - truncate to first 140 characters */}
        <small className="mt-1">
          {filing.headline || "Retrieving headline..."} <br />
        </small>
      </Link>
    </div>
  );
};

export default FilingListItem;
