import { useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useUserAuth } from "../../context/UserAuthContext";

const colorClasses = [
	"bg-primary",
	"bg-success",
	"bg-danger",
	"bg-warning",
	"bg-info",
	"bg-dark",
];

const NotificationPreferences = () => {
	const [selected, setSelected] = useState({});
	const [notificationPreferences, setNotificationPreferences] = useState({
		email: false,
		text: false,
		push: false,
		filings: {
			"8-K": false,
			"10-K": false,
			"10-Q": false,
			4: false,
			"SC 13D": false,
			"SC 13G": false,
		},
	});

	const { user } = useUserAuth();

	useEffect(() => {
		const fetchDoc = async () => {
			if (user) {
				const userDoc = doc(db, "users", user.uid);
				const userDocSnapshot = await getDoc(userDoc);
				const userNotificationPreferences =
					userDocSnapshot.data().notificationPreferences || {};

				// Set selected state based on user's notification preferences
				const selectedFilings =
					userNotificationPreferences.filings || {};
				const selectedState = {};
				for (const filing of Object.keys(
					notificationPreferences.filings
				)) {
					selectedState[filing] = selectedFilings[filing] || false;
				}
				setSelected(selectedState);
				setNotificationPreferences(userNotificationPreferences);
			}
		};

		fetchDoc();
	}, [user]); // Add user as a dependency to the useEffect hook

	const handleClick = async (key) => {
		const newSelected = { ...selected, [key]: !selected[key] };
		setSelected(newSelected);

		const newNotificationPreferences = {
			...notificationPreferences,
			filings: {
				...notificationPreferences.filings,
				[key]: newSelected[key],
			},
		};
		setNotificationPreferences(newNotificationPreferences);

		// Update the user document
		const userDoc = doc(db, "users", user.uid);
		await updateDoc(userDoc, {
			notificationPreferences: newNotificationPreferences,
		});
	};

	return (
		<Container className="mb-5">
			<Row>
				<Col className="mx-auto" xs={12} md={10}>
					<h1 className="text-center mt-5">News Updates</h1>
					<p className="text-center">
						Select the types of news updates you would like to
						receive
					</p>
					<hr
						style={{
							borderTop: "6px solid #1e293b",
							marginTop: 0,
							marginLeft: 3,
						}}
					/>
				</Col>
			</Row>
			<Row xs={2} md={4} lg={5}>
				{Object.keys(notificationPreferences.filings).map(
					(key, index) => (
						<Col key={index}>
							<div
								style={{
									position: "relative",
									width: "100%",
									cursor: "pointer",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									overflow: "hidden",
									transition:
										"background-color 0.3s ease, border 0.3s ease",
								}}
								className={
									selected[key]
										? colorClasses[
												index % colorClasses.length
											] + " text-white mb-4"
										: "border border-2 border-dark mb-4"
								}
								onClick={() => handleClick(key)}
							>
								<div style={{ paddingTop: "100%" }}></div>
								<span
									style={{
										position: "absolute",
										fontSize: "1.6em",
										textAlign: "center",
										width: "100%",
										transition: "transform 0.3s ease",
										transform: selected[key]
											? "translateY(-10px)"
											: "translateY(0)",
									}}
								>
									{key}
									<small
										className="d-block mt-2"
										style={{ fontSize: "0.6em" }}
									>
										{selected[key]
											? "SELECTED"
											: "Not selected"}
									</small>
								</span>
							</div>
						</Col>
					)
				)}
			</Row>
		</Container>
	);
};

export default NotificationPreferences;
