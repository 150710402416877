import {
  Routes,
  Route,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import ProtectedRoute from "./components/modules/ProtectedRoute";
import { ToastProvider } from "./context/ToastContext";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import PushNotifications from "./components/modules/PushNotifications";
import SidebarMenu from "./components/navs/SidebarMenu"; // Import SidebarMenu
import { Container, Row, Col } from "react-bootstrap"; // Import Bootstrap components
import { useMediaQuery } from "react-responsive";
import routes from "./config/routes"; // Import routes
import { useState, useEffect } from "react";

function NotFound() {
  return (
    <div className="text-center" style={{ marginTop: "10rem" }}>
      <h1>404 Not Found</h1>
      <p>The page you're looking for doesn't exist.</p>
      {/* go back button */}
      <span className="btn btn-link" onClick={() => window.history.back()}>
        <i className="fas fa-arrow-left me-2"></i>
        Go Back
      </span>
    </div>
  );
}

function AppContent() {
  const isBelowLg = useMediaQuery({ query: "(max-width: 991px)" });
  const location = useLocation();
  const [showSidebar, setShowSidebar] = useState(true);

  useEffect(() => {
    const currentRoute = routes.find(
      (route) => route.path === location.pathname
    );
    setShowSidebar(!(currentRoute && currentRoute.hideSidebar));
  }, [location.pathname]);

  return (
    <Container fluid={isBelowLg}>
      <Row>
        {showSidebar && (
          <Col xs={2} sm={1} md={1} lg={2}>
            <SidebarMenu />
          </Col>
        )}
        <Col
          xs={showSidebar ? 10 : 12}
          sm={showSidebar ? 11 : 12}
          md={showSidebar ? 11 : 12}
          lg={showSidebar ? 10 : 12}
        >
          <Routes>
            {routes.map(({ path, title, Component, isProtected }) => (
              <Route
                key={path}
                path={path}
                element={
                  <div>
                    <Helmet>
                      <title>
                        {title
                          ? `${title} | Privy`
                          : "Real-Time SEC Filing Alerts & Document Summaries for Day Traders and Analysts | Privy"}
                      </title>
                    </Helmet>
                    {isProtected ? (
                      <ProtectedRoute>
                        <Component />
                      </ProtectedRoute>
                    ) : (
                      <Component />
                    )}
                  </div>
                }
              />
            ))}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Col>
      </Row>
    </Container>
  );
}

function App() {
  return (
    <UserAuthContextProvider>
      <Router>
        <ToastProvider>
          <HelmetProvider>
            <PushNotifications />
            <AppContent />
          </HelmetProvider>
        </ToastProvider>
      </Router>
    </UserAuthContextProvider>
  );
}

export default App;
