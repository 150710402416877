import { useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useUserAuth } from "../../context/UserAuthContext";

const colorClasses = [
	"bg-primary",
	"bg-success",
	"bg-danger",
	"bg-warning",
	"bg-info",
	"bg-dark",
];

const Industries = () => {
	const [countMap, setCountMap] = useState({});
	const [selected, setSelected] = useState({});

	const { user } = useUserAuth();

	useEffect(() => {
		const fetchDoc = async () => {
			const docRef = doc(db, "indices", "industries");
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				setCountMap(docSnap.data().counts);
			} else {
				console.log("No such document!");
			}

			// Check if user is not null before fetching user's industries
			if (user) {
				const userDoc = doc(db, "users", user.uid);
				const userDocSnapshot = await getDoc(userDoc);
				const userIndustries = userDocSnapshot.data().industries || [];

				// Set selected state based on user's industries
				const selectedIndustries = {};
				for (const sector of Object.keys(docSnap.data().counts)) {
					selectedIndustries[sector] =
						userIndustries.includes(sector);
				}
				setSelected(selectedIndustries);
			}
		};

		fetchDoc();
	}, [user]); // Add user as a dependency to the useEffect hook

	const handleClick = (key) => {
		setSelected((prev) => {
			const newSelected = { ...prev, [key]: !prev[key] };

			// Update user's industries in Firestore
			if (user) {
				updateDoc(doc(db, "users", user.uid), {
					industries: Object.keys(newSelected).filter(
						(sector) => newSelected[sector]
					),
				});
			}

			return newSelected;
		});
	};

	return (
		<Container className="mb-5">
			<Row>
				<Col className="mx-auto" xs={12} md={10}>
					<h1 className="text-center mt-5">Industries</h1>
					<p className="text-center">
						Select the Industries you want to receive updates on
					</p>
					<p className="text-center">
						<small className="text-muted">
							NOTE: If the industry falls under a sector you've
							already selected, selecting it again is not
							necessary. You will not receive duplicate
							notifications.
						</small>
					</p>
					<hr
						style={{
							borderTop: "6px solid #1e293b",
							marginTop: 0,
							marginLeft: 3,
						}}
					/>
				</Col>
			</Row>
			<Row xs={2} md={4} lg={5}>
				{Object.keys(countMap).map((key, index) => (
					<Col key={index}>
						<div
							style={{
								position: "relative",
								width: "100%",
								cursor: "pointer",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								overflow: "hidden",
								transition:
									"background-color 0.3s ease, border 0.3s ease",
							}}
							className={
								selected[key]
									? colorClasses[
											index % colorClasses.length
										] + " text-white mb-4"
									: "border border-2 border-dark mb-4"
							}
							onClick={() => handleClick(key)}
						>
							<div style={{ paddingTop: "100%" }}></div>
							<span
								style={{
									position: "absolute",
									fontSize: "1.6em",
									textAlign: "center",
									width: "100%",
									transition: "transform 0.3s ease",
									transform: selected[key]
										? "translateY(-10px)"
										: "translateY(0)",
								}}
							>
								{key.includes(" - ")
									? key.split(" - ")[1]
									: key}
								<small
									className="d-block mt-2"
									style={{ fontSize: "0.6em" }}
								>
									{selected[key]
										? "SELECTED"
										: `${countMap[key]} companies`}
								</small>
							</span>
						</div>
					</Col>
				))}
			</Row>
		</Container>
	);
};

export default Industries;
