import React from "react";
import { Button, Tab, Container, Row, Col, Nav } from "react-bootstrap";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useUserAuth } from "../../context/UserAuthContext";
import useUserData from "./account/useUserData";
import Profile from "./account/Profile";
import Billing from "./account/Billing";
import Notifications from "./account/Notifications";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";

const Account = () => {
  const { tab = "profile" } = useParams();
  const navigate = useNavigate();
  const { user, requestFcmToken } = useUserAuth();
  const {
    subscription,
    notificationPreferences,
    setNotificationPreferences,
    fcmToken,
  } = useUserData();
  const filingTypesArray = ["8-K", "10-K", "10-Q", "4", "SC 13D", "SC 13G"];

  const handleSelect = (selectedTab) => {
    navigate(`/account/${selectedTab}`);
  };

  const handleNotificationChange = async (type, value, filingType) => {
    let newPreferences;
    if (filingType) {
      newPreferences = {
        ...notificationPreferences,
        filings: {
          ...notificationPreferences.filings,
          [filingType]: value,
        },
      };
    } else {
      newPreferences = {
        ...notificationPreferences,
        [type]: value,
      };
    }

    setNotificationPreferences(newPreferences);

    const userDocRef = doc(db, "users", user.uid);
    await updateDoc(userDocRef, {
      notificationPreferences: newPreferences,
    });
  };

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={tab}
            onSelect={handleSelect}
          >
            <Row>
              <Col
                xs={3}
                style={{
                  borderRight: "1px solid #eee",
                  borderLeft: "1px solid #eee",
                  height: "100vh",
                }}
              >
                <h4 className="my-3">Account</h4>
                <Nav variant="pills" className="flex-column">
                  {/* <Nav.Item>
                    <Nav.Link eventKey="account">Account</Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link eventKey="profile">Profile</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="billing">Billing</Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link eventKey="notifications">
                      Notification Preferences
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col xs={8}>
                <Tab.Content>
                  <Tab.Pane eventKey="account">
                    <div className="p-4 box mt-3 text-center">
                      Welcome <br />
                      {user && user.email}
                      <br />
                      <br />
                      <Link to="/pricing" className="btn btn-primary">
                        Go to pricing
                      </Link>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="profile">
                    <Profile />
                  </Tab.Pane>
                  <Tab.Pane eventKey="billing">
                    <Billing subscription={subscription} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="notifications">
                    <Notifications
                      notificationPreferences={notificationPreferences}
                      handleNotificationChange={handleNotificationChange}
                      filingTypesArray={filingTypesArray}
                      fcmToken={fcmToken}
                      requestFcmToken={requestFcmToken}
                      user={user}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Account;
