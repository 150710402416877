import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Image,
  Form,
  Alert,
  Button,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { useUserAuth } from "../../context/UserAuthContext";
import googleIcon from "assets/images/png/google.png";
import appleIcon from "assets/images/brand/apple.svg";
import microsoftIcon from "assets/images/brand/microsoft.svg";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState(""); // Add state for displayName
  const [error, setError] = useState("");
  const { user, signUp, signInWithGoogle, signInWithMicrosoft } = useUserAuth();
  let navigate = useNavigate();

  // if user is already logged in, redirect to home
  useEffect(() => {
    if (user) {
      navigate("/account");
    }
  }, [user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await signUp(email, password, displayName); // Pass displayName to signUp
      navigate("/");
    } catch (err) {
      setError("An error occurred. Please try again.");
    }
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithGoogle();
      navigate("/account");
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleMicrosoftSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithMicrosoft();
      navigate("/account");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Container
      fluid
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "80vh" }}
    >
      <Row className="w-100">
        <Col xs={12} md={4} lg={4} className="mx-auto">
          <div className="p-4 box text-center">
            <h2 className="mb-3">Sign Up</h2>

            {error && <Alert variant="danger">{error}</Alert>}

            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicDisplayName">
                <Form.Control
                  type="text"
                  placeholder="Display Name"
                  onChange={(e) => setDisplayName(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="Email address"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>

              <div className="d-grid gap-2">
                <Button variant="info" type="Submit">
                  Sign up
                </Button>
              </div>
            </Form>
            <hr />
            <div>
              <Button
                variant="social-outline"
                className="w-100"
                onClick={handleGoogleSignIn}
              >
                <Image
                  src={googleIcon}
                  alt="Google"
                  width="24"
                  height="24"
                  className="me-2"
                />
                Sign up with Google
              </Button>
              {/* <Button
                variant="social-outline"
                className="w-100 mt-2"
                onClick={handleGoogleSignIn}
              >
                <Image
                  src={appleIcon}
                  alt="Apple"
                  width="24"
                  height="24"
                  className="me-2"
                />
                Sign up with Apple
              </Button> */}
            </div>
          </div>
          <div className="box text-center">
            Already have an account? <Link to="/login">Log In</Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Signup;
