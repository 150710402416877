import React, { useState } from "react";
import { Button, Card, Col, Nav, Row, Form } from "react-bootstrap";
import LiveFeed from "./partials/LiveFeed";
import Following from "./partials/Following";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useToast } from "../../context/ToastContext";
import { useUserAuth } from "../../context/UserAuthContext";
import RightColumn from "./partials/RightColumn";

const Home = () => {
  const [activeTab, setActiveTab] = useState("liveFeed");
  const [email, setEmail] = useState("");
  const showToast = useToast();
  const { user, signInWithGoogle, loading } = useUserAuth();

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithGoogle();
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSubscribe = async () => {
    if (email) {
      try {
        await addDoc(collection(db, "subscribers"), {
          email: email,
          subscribed: true,
        });
        showToast("Success", "Subscribed successfully!", null, "info");
      } catch (error) {
        console.error("Error subscribing: ", error);
        showToast(
          "Error",
          "An error occurred. Please try again later.",
          null,
          "danger"
        );
      }
    } else {
      showToast("Warning", "Please enter your email!", null, "warning");
    }
  };

  return (
    <Row>
      <Col md={8} sm={12}>
        <Card className="sticky-col transparent-blur-card">
          <Nav
            className="nav-lb-tab nav justify-content-center d-flex"
            role="tablist"
          >
            <Nav.Item className="flex-fill">
              <Nav.Link
                role="tab"
                className={`mb-sm-3 mb-md-0 nav-link text-center ${activeTab === "liveFeed" ? "active" : ""}`}
                tabIndex="0"
                onClick={() => setActiveTab("liveFeed")}
              >
                Live Feed
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="flex-fill">
              <Nav.Link
                role="tab"
                className={`mb-sm-3 mb-md-0 nav-link text-center ${activeTab === "following" ? "active" : ""}`}
                tabIndex="0"
                onClick={() => setActiveTab("following")}
              >
                Following
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Card>
        {activeTab === "liveFeed" && <LiveFeed />}
        {activeTab === "following" && <Following />}
      </Col>
      <Col md={4} sm={12}>
        <RightColumn />
      </Col>
    </Row>
  );
};

export default Home;
