import React, { useState } from "react";
import {
  Nav,
  Dropdown,
  Image,
  Modal,
  Button,
  Spinner,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useUserAuth } from "../../context/UserAuthContext";
import { Home, LogIn, Radio, Database, Star } from "react-feather";
import SignInModal from "../views/modals/SignInModal";
import logo from "assets/images/brand/privyLogoCurved.png";

const SidebarMenu = () => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 1200px)" });
  const { logOut, user, loading } = useUserAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [showUserOptionsModal, setShowUserOptionsModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const defaultUserImage =
    "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y";

  const handleShowAuthModal = () => setShowModal(true);
  const handleCloseAuthModal = () => setShowModal(false);

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleUserOptionsModalClose = () => setShowUserOptionsModal(false);

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <div className="sticky-col">
      {isSmallScreen ? (
        <Row className="d-flex flex-column flex-shrink-0">
          <Col>
            <Nav.Link
              as={Link}
              to="/"
              className={`d-block text-decoration-none ${location.pathname === "/" ? "active" : ""}`}
              title="Icon-only"
            >
              <span className="visually-hidden">Icon-only</span>
            </Nav.Link>
          </Col>
          <Nav className="nav-pills nav-flush flex-column mb-auto text-center">
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/"
                className={`py-3 ${location.pathname === "/" ? "active" : ""}`}
                title="Home"
              >
                <Home size={24} />
              </Nav.Link>
            </Nav.Item>
            {user && (
              <>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/notifications"
                    className={`py-3 ${location.pathname === "/notifications" ? "active" : ""}`}
                    title="Notifications"
                  >
                    <Radio size={24} />
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/data"
                    className={`py-3 ${location.pathname === "/data" ? "active" : ""}`}
                    title="Data"
                  >
                    <Database size={24} />
                  </Nav.Link>
                </Nav.Item>
                {user && (
                  <>
                    <hr />
                    <Nav.Item>
                      <Nav.Link
                        as={Link}
                        to="/account"
                        className={`py-3`}
                        title="Account"
                      >
                        <Image
                          src={user.photoURL || defaultUserImage}
                          alt="User"
                          width="32"
                          height="32"
                          className="rounded-circle"
                        />
                      </Nav.Link>
                    </Nav.Item>
                  </>
                )}
              </>
            )}
          </Nav>
        </Row>
      ) : (
        <Row className="d-flex flex-column flex-shrink-0 sticky-col">
          <Nav.Link as={Link} to="/">
            <span className="h1 text-gray-800">Privy</span>
            {/* <Image src={logo} alt="Privy" width="100" height="100" /> */}
          </Nav.Link>
          <Nav className="flex-column mb-auto">
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/"
                className={`${location.pathname === "/" ? "active" : ""}`}
                aria-current="page"
              >
                <Home size={20} className="me-2" />
                Home
              </Nav.Link>
            </Nav.Item>
            {user && (
              <>
                {/* <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/following"
                    className={`${location.pathname === "/following" ? "active" : ""}`}
                  >
                    <Star size={20} className="me-2" />
                    Following
                  </Nav.Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/notifications"
                    className={`${location.pathname === "/notifications" ? "active" : ""}`}
                  >
                    <Radio size={20} className="me-2" />
                    Notifications
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    href="#"
                    className={`${location.pathname === "/data" ? "active" : ""}`}
                  >
                    <Database size={20} className="me-2" />
                    Data
                  </Nav.Link>
                </Nav.Item>
              </>
            )}
          </Nav>
          <hr className={`mt-3`} />
          {user ? (
            <>
              <Dropdown>
                <Dropdown.Toggle
                  as="a"
                  href="#"
                  className="d-flex align-items-center text-decoration-none mt-2"
                >
                  <Image
                    src={user.photoURL || defaultUserImage}
                    alt="User"
                    width="32"
                    height="32"
                    className="rounded-circle me-2"
                  />
                  <strong>{user.displayName || "User"}</strong>
                </Dropdown.Toggle>
                <Dropdown.Menu className="text-small shadow">
                  <Dropdown.Item as={Link} to="/account">
                    Account
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item href="#" onClick={handleLogout}>
                    Sign out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            <Row className="d-flex flex-column flex-shrink-0 sticky-col">
              <Button
                size="md"
                className="rounded"
                variant="info"
                onClick={handleShowAuthModal}
              >
                <LogIn size={20} className="me-2" />
                Sign In
              </Button>
            </Row>
          )}
        </Row>
      )}
      <SignInModal show={showModal} handleClose={handleCloseAuthModal} />
      <Modal show={showUserOptionsModal} onHide={handleUserOptionsModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>User Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button variant="primary" onClick={handleLogout}>
            Sign out
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SidebarMenu;
