import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Spinner,
  Container,
  Table,
  Badge,
} from "react-bootstrap";
import { useParams, Link, useNavigate } from "react-router-dom";
import { getDoc, doc, collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useToast } from "../../context/ToastContext";
import { useUserAuth } from "../../context/UserAuthContext";
import { getFunctions, httpsCallable } from "firebase/functions";
import Markdown from "react-markdown";
import { ArrowLeft } from "react-feather";
import RightColumn from "./partials/RightColumn";

const Filing = () => {
  const { filingId } = useParams();
  const navigate = useNavigate();
  const [filing, setFiling] = useState({});
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState({});
  const [email, setEmail] = useState("");
  const functions = getFunctions();
  const { user, signInWithGoogle, loading: authLoading } = useUserAuth();
  const showToast = useToast();

  useEffect(() => {
    const getFilingAndCompany = async () => {
      try {
        const filingRef = doc(db, "filings", filingId);
        const filingDoc = await getDoc(filingRef);

        if (!filingDoc.exists()) {
          setNotFound(true);
          setLoading(false);
          return;
        }

        setFiling(filingDoc.data());

        const companyRef = filingDoc.data().companyRef;
        const companyDoc = await getDoc(companyRef);
        setCompany(companyDoc.data());

        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    getFilingAndCompany();
  }, [filingId]);

  const getBgClass = (type) => {
    if (type === "10-K" || type === "10-K/A") {
      return "bg-warning";
    } else if (type === "10-Q" || type === "10-Q/A") {
      return "bg-success";
    } else {
      return "bg-danger";
    }
  };

  if (loading) {
    return <Spinner animation="border" />;
  }

  if (notFound) {
    return (
      <Container>
        <Row>
          <Col
            md={{ span: 8, offset: 2 }}
            lg={{ span: 8, offset: 2 }}
            className="text-center text-align-center"
            style={{
              height: "50vh",
            }}
          >
            <h3>Filing analysis not found</h3>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Row>
      <Col md={8} sm={12}>
        <Card className="sticky-col transparent-blur-card">
          {/* ArrowLeft */}
          <ArrowLeft
            onClick={() => navigate(-1)}
            className="mt-3 cursor-pointer"
          />
        </Card>
        <Container>
          <Row className="mt-6 mb-3">
            <Col md={12} lg={12}>
              {user && user.role === "admin" && (
                <Button
                  variant="secondary"
                  size="sm"
                  className="float-end"
                  onClick={async () => {
                    const regenerateFilingAnalysis = httpsCallable(
                      functions,
                      "regenerateFilingAnalysis"
                    );
                    try {
                      await regenerateFilingAnalysis({
                        filingId,
                      });
                    } catch (err) {
                      console.log(err);
                    }
                  }}
                >
                  Regenerate Analysis
                </Button>
              )}

              <h1 className="d-flex align-items-center">
                <span
                  className="avatar avatar-primary me-0 mb-1 mb-lg-0"
                  style={{
                    minWidth: "160px",
                    minHeight: "160px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflowWrap: "break-word",
                    textAlign: "center",
                    lineHeight: "1.5",
                  }}
                >
                  <span
                    className={`avatar-initials ${getBgClass(filing.type)} rounded h1`}
                  >
                    {filing.type}
                  </span>
                </span>
                <Link to={`/companies/${company.cik}`} className="ms-3">
                  {company.tradeName ? company.tradeName : company.name}
                </Link>
              </h1>
              <div>
                <div className="mb-3">
                  <Badge pill bg="light" text="dark" className="me-1">
                    {filing.updatedAt?.toDate().toLocaleString()}
                  </Badge>
                  <br />
                  <b>Accession Number:</b> {filing.accessionNumber}
                  <br />
                  <h2 className="mt-3">{filing.headline}</h2>
                </div>
                <hr />
                <div className="mb-3">
                  <Markdown>{filing.summary}</Markdown>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }}>
              <h4 className="mb-3">Details</h4>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Accession Number</th>
                    <th>Sentiment</th>
                    <th>Impact</th>
                    <th>Weight</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{filing.type}</td>
                    <td>{filing.accessionNumber}</td>
                    <td>{filing.sentiment}</td>
                    <td>{filing.impact}</td>
                    <td>{filing.weight}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </Col>
      <Col md={4} sm={12}>
        <RightColumn />
      </Col>
    </Row>
  );
};

export default Filing;
