import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  query,
  limit,
  getDocs,
  onSnapshot,
  doc,
  addDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useUserAuth } from "../../context/UserAuthContext";
import { loadStripe } from "@stripe/stripe-js";
// import LoginModal from "../modals/loginModal";
import { Card, Button, Row, Col, Container, ListGroup } from "react-bootstrap";

const Pricing = () => {
  const { user } = useUserAuth();
  const [products, setProducts] = useState([]);
  const [, setProductPriceId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // get the products list from firestore and get all the pricings from the pricing subcollection
    let allProducts = [];
    const getProducts = async () => {
      // Query all documents in the "products" collection
      const productsRef = collection(db, "products");

      // Create a query against the collection.
      const q = query(productsRef, where("active", "==", true));

      const querySnapshot = await getDocs(q);

      for (let i = 0; i < querySnapshot.docs.length; i++) {
        const doc = querySnapshot.docs[i];

        // Query all documents in the "pricing" subcollection
        const pricesRef = collection(db, "products", doc.id, "prices");

        // Create a query against the collection.
        const pricesQuery = query(pricesRef, limit(1));

        const pricesQuerySnapshot = await getDocs(pricesQuery);

        // add the price to the product
        const product = {
          ...doc.data(),
          price: {
            ...pricesQuerySnapshot.docs[0].data(),
            id: pricesQuerySnapshot.docs[0].id,
          },
        };

        console.log(JSON.stringify(product, null, 2));
        // add the product to the products array
        allProducts.push(product);
      }

      setProducts(allProducts);
    };
    getProducts();
  }, [navigate, products.length]);

  const createCheckoutSession = async (price) => {
    // if no user, redirect to login
    if (!user) {
      navigate("/signup");
      return;
    }
    // create a document in the checkout_sessions subcollection under the user document
    const usersCollection = collection(db, "users");
    const userRef = doc(usersCollection, user.uid);
    const checkoutSessionsCollection = collection(userRef, "checkout_sessions");

    const addedSession = await addDoc(checkoutSessionsCollection, {
      price,
      success_url: `${window.location.origin}/purchase-confirmed`,
      cancel_url: `${window.location.origin}/pricing`,
    });

    if (addedSession) {
    } else {
    }

    // get the newly created doc via addedSession.id
    const checkoutSessionRef = doc(checkoutSessionsCollection, addedSession.id);

    onSnapshot(checkoutSessionRef, async (doc) => {
      // get the checkout session id from the session document
      const { sessionId, error } = doc.data();

      if (error) {
        // show an error message

        // stop listening to the checkout session
        return;
      }

      if (sessionId) {
        // We have a session, let's redirect to Checkout
        // Init Stripe
        const stripe = await loadStripe(
          process.env.REACT_APP_STRIPE_PUBLIC_KEY
        );
        await stripe.redirectToCheckout({ sessionId });
      }
    });
  };

  const sendToSubscribeView = () => {
    navigate("/subscribe");
  };

  // product looks like:
  // {
  // 	"images": [],
  // 	"tax_code": "txcd_10000000",
  // 	"metadata": {
  // 	  "firebaseRole": "ssss"
  // 	},
  // 	"role": "ssss",
  // 	"name": "test-product-2",
  // 	"active": true,
  // 	"description": null,
  // 	"price": {
  // 	  "interval_count": 1,
  // 	  "tax_behavior": "unspecified",
  // 	  "metadata": {},
  // 	  "product": "prod_PeHwEUo6ywcaeg",
  // 	  "tiers": null,
  // 	  "recurring": {
  // 		"interval_count": 1,
  // 		"usage_type": "licensed",
  // 		"aggregate_usage": null,
  // 		"interval": "month",
  // 		"trial_period_days": null
  // 	  },
  // 	  "active": true,
  // 	  "description": null,
  // 	  "billing_scheme": "per_unit",
  // 	  "trial_period_days": null,
  // 	  "unit_amount": 99900,
  // 	  "type": "recurring",
  // 	  "transform_quantity": null,
  // 	  "tiers_mode": null,
  // 	  "currency": "usd",
  // 	  "interval": "month",
  // 	  "id": "price_1OozMkG1fzwg0zNdNtljzeEn"
  // 	}
  //   }

  return (
    <Container
      // className="d-flex align-items-center justify-content-center"
      fluid
      style={{ minHeight: "80vh" }}
    >
      {" "}
      <Row className="mt-10">
        <Col md={12}>
          <div className="mb-2 mb-xl-0 text-center px-1">
            <h3 className="display-3 fw-bold mb-2 ls-sm">Subscription Plans</h3>
            <p className="mb-5">Choose a plan that fits your needs</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 3 }} lg={{ span: 3 }}>
          <Card className="border-0 shadow-none">
            <Card.Body className="text-center">
              <Card.Title>Free</Card.Title>

              <Card.Text>
                <h1>Free</h1>
              </Card.Text>

              {/* list of features */}
              <Card.Text>
                <ListGroup bsPrefix="list-unstyled mb-4">
                  <ListGroup.Item className="mb-1" bsPrefix="list-item">
                    <span>100 Real-time notifications</span>
                    <span className="text-muted ms-2">🔔</span>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Text>

              <Button
                variant="success"
                size="md"
                // full width
                // className="w-100"
                onClick={() => {
                  sendToSubscribeView();
                }}
              >
                Get access
              </Button>
            </Card.Body>
          </Card>
        </Col>
        {products &&
          products.map((product, idx) => (
            // free plan

            <Col key={idx} md={{ span: 3 }} lg={{ span: 3 }}>
              <Card className="border-0 shadow-none">
                <Card.Body className="text-center">
                  <Card.Title>{product.name}</Card.Title>
                  <Card.Text>{product.description}</Card.Text>
                  <Card.Text>
                    <h1>${(product.price.unit_amount / 100).toFixed(2)} </h1>
                    /month USD
                  </Card.Text>

                  {/* list of features */}
                  <Card.Text>
                    <ListGroup bsPrefix="list-unstyled mb-4">
                      <ListGroup.Item className="mb-1" bsPrefix="list-item">
                        <span>100 Real-time notifications</span>
                        <span className="text-muted ms-2">🔔</span>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Text>

                  <Button
                    variant="success"
                    size="md"
                    // full width
                    // className="w-100"
                    onClick={() => {
                      setProductPriceId(product.price.id);
                      createCheckoutSession(product.price.id);
                    }}
                  >
                    Get access
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
      </Row>
    </Container>
  );
};

export default Pricing;
