import React, { useState } from "react";
import { Button } from "react-bootstrap";
import SignInModal from "../../views/modals/SignInModal";

const Following = () => {
  const [showModal, setShowModal] = useState(false);

  const handleCloseAuthModal = () => setShowModal(false);

  return (
    <div className="border border-1 border-top-0 border-start-1 border-end-1 border-bottom-0 p-10 vh-100 text-center">
      <h1>Stay Ahead</h1>
      <div className="row">
        <p>
          Receive notifications and follow live updates from sectors,
          industries, and companies you care about.
        </p>

        {/* This column will take up the remaining space */}
      </div>
      <SignInModal show={showModal} handleClose={handleCloseAuthModal} />

      <Button
        size="lg"
        variant="dark"
        className="rounded"
        onClick={() => setShowModal(true)}
      >
        Join Now
      </Button>
    </div>
  );
};

export default Following;
