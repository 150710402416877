import React, { useState, useEffect, useMemo } from "react";
import { ListGroup, Col, Container, Row } from "react-bootstrap";
import { db } from "../../firebase";

import {
  collection,
  query,
  orderBy,
  limit,
  onSnapshot,
  getDoc,
} from "firebase/firestore";

import StoryListItem from "./partials/StoryListItem";

const Main = () => {
  const [stories, setStories] = useState([]);
  const today = useMemo(() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }, []);

  const yesterday = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    date.setHours(0, 0, 0, 0);
    return date;
  }, []);

  useEffect(() => {
    const storiesCollection = collection(db, "stories");

    const q = query(
      storiesCollection,
      orderBy("createdAt", "desc"), // Order by createdAt field in descending order
      limit(40) // Only get the last 20 stories
    );

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const storiesData = await Promise.all(
        snapshot.docs.map(async (story) => {
          const storyData = {
            id: story.id,
            ...story.data(),
          };

          return storyData;
        })
      );

      setStories(storiesData);
    });

    // Clean up the subscription on unmount
    return () => unsubscribe();
  }, []);

  let lastDate = null;

  return (
    <Container className="mt-2 mb-20" fluid>
      <Row>
        <Col md={{ span: 5, offset: 2 }} lg={{ span: 5, offset: 2 }}>
          <div className="mb-2 mb-xl-0 text-center text-md-start px-1">
            <h1 className="display-3 fw-bold mb-2 ls-sm">Live News</h1>
            {/* zero top and bottom margin for hr */}
            <hr
              style={{
                borderTop: "6px solid #1e293b",
                marginTop: 0,
                marginLeft: 3,
              }}
            />
            <p className="mb-5">
              Live news stories from the top 2000 public companies
            </p>
          </div>
        </Col>
      </Row>

      {stories.map((story) => {
        const createdAtDate = story.createdAt?.toDate();
        createdAtDate.setHours(0, 0, 0, 0);
        const createdAtDateString = createdAtDate.toLocaleDateString("en-US", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        });

        const showDate = createdAtDateString !== lastDate;
        lastDate = createdAtDateString;

        return (
          <Row key={story.id}>
            <Col className="text-end" md={2} lg={2} xl={2}>
              {showDate && <h5 className="mt-2">{createdAtDateString}</h5>}
            </Col>
            <Col md={10} lg={10} xl={10}>
              <ListGroup>
                <StoryListItem
                  key={story.id}
                  story={story}
                  createdAtDate={createdAtDate}
                  today={today}
                  yesterday={yesterday}
                />
              </ListGroup>
            </Col>
            <Col></Col>
          </Row>
        );
      })}
    </Container>
  );
};

export default Main;
