// Filing.js
import React from "react";
import { Badge, ListGroup } from "react-bootstrap";
import TimeAgo from "react-timeago";
import { CSSTransition } from "react-transition-group";
import englishStrings from "react-timeago/lib/language-strings/en";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import { Link } from "react-router-dom";

const formatter = buildFormatter({
  ...englishStrings,
  second: "just now",
  seconds: "%ds",
});

const FilingListItem = ({ story, updatedAtDate, today, yesterday }) => {
  // Only render the story if it was created today or yesterday
  return (
    <CSSTransition key={story.id} timeout={1000} classNames="ListGroupItem">
      <ListGroup.Item className="border-0 p-1" key={story.id}>
        <div className="d-flex">
          <div
            className={`avatar avatar-initials text-white bg-dark h5`}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "64px",
              height: "64px",
              overflowWrap: "break-word",
              padding: "5px",
              textAlign: "center",
              lineHeight: "1.5", // Adjust as needed
            }}
          >
            News
          </div>
          <Link to={`/storys/${story.id}`} className="ms-3">
            {/* badge for weight of story - positioned to the right */}
            <h5 className="lh-1">
              <span>
                {/* show each of the strings in the entities field */}
                {story.entities &&
                  story.entities.map((entity) => (
                    <Badge
                      key={entity}
                      bg="secondary"
                      className="me-1"
                      style={{ fontSize: "0.75rem" }}
                    >
                      {entity}
                    </Badge>
                  ))}
              </span>
            </h5>
            {/* title summary - truncate to first 140 characters */}
            <small className="mt-1">
              {story.title || "Retrieving title..."} <br />
              <span className="text-muted">
                <TimeAgo
                  date={story.updatedAt?.toDate()}
                  formatter={formatter}
                  minPeriod={1} // Update every second
                />
              </span>
            </small>
          </Link>
        </div>
      </ListGroup.Item>
    </CSSTransition>
  );
};

export default FilingListItem;
