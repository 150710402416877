import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";

const Billing = ({ subscription }) => {
  const handlePortalLink = async () => {
    const functions = getFunctions();
    const getPortalLink = httpsCallable(
      functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );
    try {
      const { data } = await getPortalLink({
        returnUrl: window.location.origin,
      });
      if (data.url) {
        window.location.assign(data.url);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="box mt-3">
      <h4>Subscription</h4>{" "}
      {subscription ? (
        // center the text and button vertically
        <div className="d-flex flex-column align-items-center">
          <p>{subscription.status} </p>
          <p>{subscription.role} </p>
          <Button onClick={handlePortalLink} className="btn btn-primary">
            Manage Subscription
          </Button>
        </div>
      ) : (
        <>
          <p>No active subscription</p>
          <Link to="/pricing" className="btn btn-primary">
            Subscribe
          </Link>
        </>
      )}
    </div>
  );
};

export default Billing;
