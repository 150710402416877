import React, { useState, useEffect, useRef, useCallback } from "react";
import { ListGroup, Button } from "react-bootstrap";
import { db } from "../../../firebase";
import {
  collection,
  query,
  orderBy,
  limit,
  onSnapshot,
  getDoc,
  getDocs,
  startAfter,
  where,
} from "firebase/firestore";
import FilingListItem from "../partials/FilingListItem";

const LiveFeed = () => {
  const [filings, setFilings] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filterType, setFilterType] = useState("all");
  const observer = useRef();

  useEffect(() => {
    const fetchInitialData = async () => {
      const filingsCollection = collection(db, "filings_metadata");
      let q = query(filingsCollection, orderBy("updatedAt", "desc"), limit(40));

      if (filterType !== "all") {
        q = query(
          filingsCollection,
          where("type", "==", filterType),
          orderBy("updatedAt", "desc"),
          limit(40)
        );
      }

      const unsubscribe = onSnapshot(q, async (snapshot) => {
        const filingsData = await Promise.all(
          snapshot.docs.map(async (filing) => {
            const filingData = {
              id: filing.id,
              ...filing.data(),
            };

            const companyRef = filingData.companyRef;
            if (companyRef) {
              const companySnapshot = await getDoc(companyRef);
              if (companySnapshot.exists()) {
                filingData.company = companySnapshot.data();
              }
            }

            return filingData;
          })
        );

        setFilings(filingsData);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      });

      return () => unsubscribe();
    };

    fetchInitialData();
  }, [filterType]);

  const loadMore = useCallback(async () => {
    if (loading || !lastVisible) return;

    setLoading(true);
    const filingsCollection = collection(db, "filings_metadata");
    let q = query(
      filingsCollection,
      orderBy("updatedAt", "desc"),
      startAfter(lastVisible),
      limit(40)
    );

    if (filterType !== "all") {
      q = query(
        filingsCollection,
        where("type", "==", filterType),
        orderBy("updatedAt", "desc"),
        startAfter(lastVisible),
        limit(40)
      );
    }

    const snapshot = await getDocs(q);
    const filingsData = await Promise.all(
      snapshot.docs.map(async (filing) => {
        const filingData = {
          id: filing.id,
          ...filing.data(),
        };

        const companyRef = filingData.companyRef;
        if (companyRef) {
          const companySnapshot = await getDoc(companyRef);
          if (companySnapshot.exists()) {
            filingData.company = companySnapshot.data();
          }
        }

        return filingData;
      })
    );

    setFilings((prevFilings) => [...prevFilings, ...filingsData]);
    setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
    setLoading(false);
  }, [lastVisible, loading, filterType]);

  const lastFilingElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, loadMore]
  );

  return (
    <>
      {/* <div className="text-center border border-1 border-top-0 border-start-1 border-end-1 border-bottom-0 py-2">
        <div className="d-flex justify-content-center">
          <Button
            className="mx-2 flex-fill"
            size="md"
            variant={filterType === "all" ? "light" : "outline-light"}
            onClick={() => setFilterType("all")}
          >
            All
          </Button>
          <Button
            className="mx-2 flex-fill"
            size="md"
            variant={filterType === "news" ? "light" : "outline-light"}
            onClick={() => setFilterType("news")}
          >
            News
          </Button>
          <Button
            className="mx-2 flex-fill"
            size="md"
            variant={filterType === "sec_filings" ? "light" : "outline-light"}
            onClick={() => setFilterType("sec_filings")}
          >
            SEC Filings
          </Button>
        </div>
      </div> */}
      <ListGroup
        style={{ position: "relative" }}
        className="vh-100 border rounded-0 border-top-0"
      >
        {filings &&
          filings.map((filing, index) => (
            <ListGroup.Item
              key={filing.id}
              className={`border border-1 rounded-0 border-end-0 border-start-0 ${
                index === filings.length - 1 ? "" : "border-bottom-0"
              } ${index === 0 ? "border-top-0" : ""}`}
              ref={index === filings.length - 1 ? lastFilingElementRef : null}
            >
              <FilingListItem filing={filing} />
            </ListGroup.Item>
          ))}
      </ListGroup>
      {loading && <div>Loading more filings...</div>}
    </>
  );
};

export default LiveFeed;
