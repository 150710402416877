import React, { useState } from "react";
import { Button, Card, Col, Image, Form } from "react-bootstrap";
import { Mail } from "react-feather";
import googleIcon from "assets/images/brand/google.svg";
import appleIcon from "assets/images/brand/apple.svg";
import microsoftIcon from "assets/images/brand/microsoft.svg";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { useToast } from "../../../context/ToastContext";
import { useUserAuth } from "../../../context/UserAuthContext";
import { Link } from "react-router-dom";

const RightColumn = () => {
  const [email, setEmail] = useState("");
  const showToast = useToast();
  const { user, signInWithGoogle, loading, signInWithMicrosoft } =
    useUserAuth();

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithGoogle();
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleMicrosoftSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithMicrosoft();
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSubscribe = async () => {
    if (email) {
      try {
        await addDoc(collection(db, "subscribers"), {
          email: email,
          subscribed: true,
        });
        showToast("Success", "Subscribed successfully!", null, "info");
      } catch (error) {
        console.error("Error subscribing: ", error);
        showToast(
          "Error",
          "An error occurred. Please try again later.",
          null,
          "danger"
        );
      }
    } else {
      showToast("Warning", "Please enter your email!", null, "warning");
    }
  };

  const handleRequestPublishing = async () => {
    if (email) {
      try {
        await addDoc(collection(db, "publishing_requests"), {
          email: email,
          requested: true,
        });
        showToast(
          "Success",
          "Request sent successfully! We'll get back to you soon.",
          null,
          "info"
        );
      } catch (error) {
        console.error("Error requesting publishing: ", error);
        showToast(
          "Error",
          "An error occurred. Please try again later.",
          null,
          "danger"
        );
      }
    } else {
      showToast("Warning", "Please enter your email!", null, "warning");
    }
  };

  return (
    <>
      {!user && (
        <div className="sticky-col">
          {loading ? (
            <></>
          ) : (
            <>
              <Card className="sticky-col p-4 transparent-blur-card rounded-top">
                <h5>New to Privy?</h5>
                <p>
                  <small>Sign up to get personalized notifications</small>
                </p>
                <Button
                  variant="social-outline"
                  className="w-100 text-start d-flex align-items-center"
                  onClick={handleGoogleSignIn}
                >
                  <Image
                    src={googleIcon}
                    alt="Google"
                    width="24"
                    height="24"
                    className="me-2"
                  />
                  Sign up with Google
                </Button>
                {/* <Button
                  variant="social-outline"
                  className="w-100 mt-2 text-start d-flex align-items-center"
                  onClick={handleGoogleSignIn}
                >
                  <Image
                    src={appleIcon}
                    alt="Apple"
                    width="24"
                    height="24"
                    className="me-2"
                  />
                  Sign up with Apple
                </Button> */}
                <Button
                  variant="social-outline"
                  className="w-100 mt-2 text-start d-flex align-items-center"
                  as={Link}
                  to="/signup"
                >
                  <Mail size={26} className="me-2" />
                  Sign up with email
                </Button>
              </Card>
              <Card className="border p-4 transparent-blur-card mt-3 rounded">
                <h4>Want to publish with us?</h4>
                <p>
                  Get your articles published on Privy and reach a wider
                  audience
                </p>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mb-3"
                />
                <Button
                  variant="outline"
                  size="md"
                  onClick={handleRequestPublishing}
                >
                  Request Publishing
                </Button>
              </Card>
              <small className="text-center d-block mt-3">
                <Link>Terms of Service</Link> | <Link>Privacy Policy</Link>{" "}
                <br />© 2024 Privy
              </small>

              {/* <Card className="border p-4 transparent-blur-card mt-3 rounded">
                <h4>Subscribe to the daily newsletter</h4>
                <p>
                  Get the latest news from Privy delivered to your inbox daily.
                </p>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mb-3"
                />
                <Button variant="outline" size="md" onClick={handleSubscribe}>
                  Subscribe Now
                </Button>
              </Card> */}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default RightColumn;
