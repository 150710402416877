import {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  updateProfile,
  updatePassword,
  getAdditionalUserInfo,
  OAuthProvider, // Import OAuthProvider for Microsoft login
} from "firebase/auth";
import { getToken } from "firebase/messaging";
import { auth } from "../firebase";
import { doc, setDoc } from "firebase/firestore";
import { db, messaging } from "../firebase"; // assuming you have exported your firestore instance from firebase.js

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [state, setState] = useState({
    user: null,
    userRole: null,
    loading: true,
  });

  const updateDisplayName = useCallback(
    (name) => {
      if (state && state.user) {
        return updateProfile(state.user, { displayName: name });
      } else {
        throw new Error("No user is logged in");
      }
    },
    [state]
  );

  const changePassword = useCallback(
    (newPassword) => {
      if (state && state.user) {
        return updatePassword(state.user, newPassword);
      } else {
        throw new Error("No user is logged in");
      }
    },
    [state]
  );

  const logIn = useCallback(async (email, password) => {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    return userCredential;
  }, []);

  const requestFcmToken = useCallback(async (user) => {
    try {
      // Request permission for push notifications
      const permission = await Notification.requestPermission();
      if (permission !== "granted") {
        throw new Error("Permission not granted for Notification");
      }
      // log process.env.REACT_APP_VAPID_KEY
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
      });

      // Save the token to firestore
      await setUserFcmToken(user.uid, token);
    } catch (error) {
      console.error("Error getting FCM token:", error);
    }
  }, []);

  const signUp = useCallback(
    async (email, password, displayName) => {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      // if there is a user, run setTimeout for one second then run requestFcmToken
      if (userCredential.user) {
        // If displayName is provided, update the user's profile
        if (displayName) {
          await updateProfile(userCredential.user, { displayName });
        }
        setTimeout(() => {
          requestFcmToken(userCredential.user);
        }, 1000);
      }
      return userCredential;
    },
    [requestFcmToken]
  );

  const logOut = useCallback(() => {
    return signOut(auth);
  }, []);

  const signInWithGoogle = useCallback(async () => {
    const googleAuthProvider = new GoogleAuthProvider();
    const userCredential = await signInWithPopup(auth, googleAuthProvider);
    const { isNewUser } = getAdditionalUserInfo(userCredential);

    console.log("userCredential.user", userCredential.user);
    // Extract photoURL from userCredential
    const photoURL = userCredential.user.photoURL;

    // Update the user's profile with the photoURL if it exists
    if (photoURL) {
      await updateProfile(userCredential.user, { photoURL });
    }

    if (isNewUser) {
      setTimeout(() => {
        requestFcmToken(userCredential.user);
      }, 1000);
    }
    return userCredential;
  }, [requestFcmToken]);

  const signInWithMicrosoft = useCallback(async () => {
    const microsoftProvider = new OAuthProvider("microsoft.com");
    const userCredential = await signInWithPopup(auth, microsoftProvider);
    const { isNewUser } = getAdditionalUserInfo(userCredential);

    console.log("userCredential.user", userCredential.user);
    // Extract photoURL from userCredential
    const photoURL = userCredential.user.photoURL;

    // Update the user's profile with the photoURL if it exists
    if (photoURL) {
      await updateProfile(userCredential.user, { photoURL });
    }

    if (isNewUser) {
      setTimeout(() => {
        requestFcmToken(userCredential.user);
      }, 1000);
    }
    return userCredential;
  }, [requestFcmToken]);

  async function setUserFcmToken(uid, token) {
    const userRef = doc(db, "users", uid);
    await setDoc(
      userRef,
      {
        fcmToken: token,
        notificationPreferences: {
          push: true,
        },
      },
      { merge: true }
    );
  }

  async function getCustomClaimRole(user) {
    if (!user) return null;
    await user.getIdToken(true);
    const decodedToken = await user.getIdTokenResult();
    const { admin, stripeRole } = decodedToken.claims;
    return { admin, subscription: stripeRole };
  }

  const unsubscribe = useRef(null);

  useEffect(() => {
    unsubscribe.current = onAuthStateChanged(auth, async (currentuser) => {
      if (currentuser) {
        const decodedToken = await getCustomClaimRole(currentuser);
        setState((s) => ({
          ...s,
          userRole: decodedToken,
          user: currentuser,
          loading: false,
        }));
      } else {
        setState((s) => ({
          ...s,
          userRole: null,
          user: null,
          loading: false,
        }));
      }
    });

    // Return a cleanup function that is called when the component unmounts
    return () => unsubscribe.current && unsubscribe.current();
  }, []);

  const contextValue = useMemo(
    () => ({
      changePassword,
      loading: state.loading,
      logIn,
      logOut,
      signInWithGoogle,
      signInWithMicrosoft, // Add Microsoft sign-in to context
      signUp,
      updateDisplayName,
      user: state.user,
      userRole: state.userRole,
      requestFcmToken,
      photoURL: state.user ? state.user.photoURL : null, // Add photoURL to context
    }),
    [
      state,
      changePassword,
      logIn,
      logOut,
      signInWithGoogle,
      signInWithMicrosoft, // Add Microsoft sign-in to context
      signUp,
      updateDisplayName,
      requestFcmToken,
    ]
  );

  return (
    <userAuthContext.Provider value={contextValue}>
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
