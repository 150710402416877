import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import Sectors from "./Sectors";
import Industries from "./Industries";
import NotificationPreferences from "./NotificationPreferences";

function Wizard() {
	const handleComplete = () => {
		alert("Form completed!");
		// Handle form completion logic here
	};

	const tabChanged = ({ prevIndex, nextIndex }) => {
		// console.log("prevIndex", prevIndex);
		// console.log("nextIndex", nextIndex);
	};

	return (
		<>
			<FormWizard
				shape="square"
				color="#333"
				onComplete={handleComplete}
				onTabChange={tabChanged}
			>
				<FormWizard.TabContent
					title="Select sectors"
					icon={<i className="material-icons">space_dashboard</i>}
				>
					<Sectors />
				</FormWizard.TabContent>
				<FormWizard.TabContent
					title="Select industries"
					icon={<i className="material-icons">factory</i>}
				>
					<Industries />
				</FormWizard.TabContent>
				<FormWizard.TabContent
					title="Notification preferences"
					icon={
						<i className="material-icons">notifications_active</i>
					}
				>
					<NotificationPreferences />
				</FormWizard.TabContent>
			</FormWizard>
		</>
	);
}

export default Wizard;
