import { useEffect } from "react";
import { useToast } from "../../context/ToastContext";
import { getMessaging, onMessage } from "firebase/messaging";

const PushNotifications = () => {
	const showToast = useToast();

	useEffect(() => {
		const messaging = getMessaging();

		onMessage(messaging, (payload) => {
			// Customize notification here
			const { title, body } = payload.notification;

			const { link } = payload.fcmOptions;

			showToast(title, body, link, "info");
		});
	}, []);

	// ...
};

export default PushNotifications;
