// ToastContext.js
import { createContext, useState, useContext } from "react";
import ToastMessage from "../components/modules/ToastMessage";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
	const [toast, setToast] = useState({
		title: "",
		body: "",
		link: "",
		type: "info",
		visible: false,
	});

	const showToast = (title, body, link, type) => {
		setToast({ title, body, link, type, visible: true });
	};

	const hideToast = () => {
		setToast({ ...toast, visible: false });
	};

	return (
		<ToastContext.Provider value={showToast}>
			{children}
			{toast.visible && (
				<ToastMessage {...toast} closeToast={hideToast} />
			)}
		</ToastContext.Provider>
	);
};

export const useToast = () => {
	return useContext(ToastContext);
};
