// ToastMessage.js
import { m } from "framer-motion";
import { Toast } from "react-bootstrap";
import { Link } from "react-router-dom";

const ToastMessage = ({ title, body, link, type, closeToast }) => {
  const renderIcon = () => {
    switch (type) {
      case "info":
        return <i className="fe fe-info text-info"></i>;
      case "warning":
        return <i className="fe fe-alert-triangle text-warning"></i>;
      case "success":
        return <i className="fe fe-check-circle text-success"></i>;
      default:
        return null;
    }
  };

  return (
    <Toast
      onClose={closeToast}
      delay={7000}
      autohide
      style={{
        position: "fixed",
        top: 40,
        right: 20,
        zIndex: 9999,
        borderRadius: "1em",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Link to={link} className="text-decoration-none">
        <Toast.Header>
          {renderIcon()}
          <strong className="me-auto ms-2">{title}</strong>
          <small>Just now</small>
        </Toast.Header>
        <Toast.Body>{body}</Toast.Body>
      </Link>
    </Toast>
  );
};

export default ToastMessage;
