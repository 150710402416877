import React from "react";
import { ListGroup, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

const Notifications = ({
  notificationPreferences,
  handleNotificationChange,
  filingTypesArray,
  fcmToken,
  requestFcmToken,
  user,
}) => {
  return (
    <>
      <div className="box mt-3">
        <h4>Notifications</h4>
        <ListGroup variant="flush" className="mt-3">
          <ListGroup.Item className="d-flex align-items-center justify-content-between px-0 py-2">
            <div>
              Notify me by push notification{" "}
              {!fcmToken && (
                <small>
                  ( To enable in your browser settings{" "}
                  <a
                    href="https://support.google.com/chrome/answer/3220216?co=GENIE.Platform%3DDesktop&hl=en"
                    target="_blank"
                    rel="noreferrer"
                    className="text-danger btn-link"
                  >
                    follow these instructions
                  </a>{" "}
                  After enabling,{" "}
                  <Link
                    to="/settings"
                    onClick={(e) => e.preventDefault() || requestFcmToken(user)}
                    className="text-danger btn-link"
                  >
                    click here to allow push notifications
                  </Link>
                  )
                </small>
              )}
            </div>
            <div>
              <Form>
                <Form.Check
                  name="push"
                  type="checkbox"
                  className=" form-switch"
                  checked={notificationPreferences.push}
                  onChange={(e) =>
                    handleNotificationChange("push", e.target.checked)
                  }
                />
              </Form>
            </div>
          </ListGroup.Item>
        </ListGroup>
      </div>
      <div className="box mt-5">
        <small className="text-muted">
          Choose what type of filings you would like to be notified about
        </small>
        <ListGroup variant="flush" className="mt-3">
          {filingTypesArray.map((filingType) => (
            <ListGroup.Item
              key={filingType}
              className="d-flex align-items-center justify-content-between px-0 py-2"
            >
              <div>
                <b className="text-black">{filingType} </b>
              </div>
              <div>
                <Form>
                  <Form.Check
                    name={filingType}
                    type="checkbox"
                    className=" form-switch"
                    checked={notificationPreferences.filings[filingType]}
                    onChange={(e) =>
                      handleNotificationChange(
                        null,
                        e.target.checked,
                        filingType
                      )
                    }
                  />
                </Form>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
    </>
  );
};

export default Notifications;
