import { useEffect, useState } from "react";
import {
	Col,
	Row,
	Container,
	Button,
	Card,
	Image,
	Tooltip,
	OverlayTrigger,
} from "react-bootstrap";
import algoliasearch from "algoliasearch/lite";
import {
	InstantSearch,
	Hits,
	Configure,
	useInstantSearch,
	SearchBox,
	RefinementList,
} from "react-instantsearch";
import { Link } from "react-router-dom";

import "instantsearch.css/themes/reset.css";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useUserAuth } from "../../context/UserAuthContext";

const searchClient = algoliasearch(
	process.env.REACT_APP_ALGOLIA_APP_ID,
	process.env.REACT_APP_ALGOLIA_SEARCH_KEY
);

const CompanyResults = ({
	hit: company,
	selectedCompanies,
	handleButtonClick,
}) => {
	return (
		<Link to={`/companies/${company.cik}`}>
			<Card className="card-bordered mb-2 card-hover cursor-pointer">
				<Card.Body className="p-1">
					<div>
						<div className="d-lg-flex">
							<div className="mb-3 mb-lg-0">
								<Image
									src={company.logo}
									alt={company.company}
									className="icon-shape bg-gray-200 rounded-3 icon-xxl p-1"
								/>
							</div>
							<div className="w-100 ms-lg-4">
								<div className="d-flex justify-content-between ">
									<div>
										<h6 className="mb-1 text">
											{company.name}
										</h6>
										<div></div>
									</div>
									<div>
										<OverlayTrigger
											overlay={
												<Tooltip>
													{selectedCompanies.includes(
														company.cik
													)
														? "Unsubscribe"
														: "Subscribe"}
												</Tooltip>
											}
										>
											<Button
												className={
													selectedCompanies.includes(
														company.cik
													)
														? "btn btn-success btn-sm"
														: "btn btn-light btn-sm"
												}
												onClick={(event) => {
													event.preventDefault();
													handleButtonClick(
														company.cik
													);
												}}
											>
												{selectedCompanies.includes(
													company.cik
												) ? (
													<i className="fe fe-check"></i>
												) : (
													<i className="fe fe-plus"></i>
												)}
											</Button>
										</OverlayTrigger>
									</div>
								</div>

								<Row>
									<Col md={6} xs={12}>
										<div>
											<span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="14"
													height="14"
													fill="currentColor"
													className="bi bi-geo-alt-fill text-muted align-text-bottom"
													viewBox="0 0 16 16"
												>
													<path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
												</svg>
											</span>
											<small className="ms-1">
												{company.city} {company.state}
											</small>
										</div>
									</Col>
									<Col md={6} xs={12}>
										<div>
											<span>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="14"
													height="14"
													fill="currentColor"
													className="bi bi-building  text-muted align-text-bottom"
													viewBox="0 0 16 16"
												>
													<path
														fillRule="evenodd"
														d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
													/>
													<path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
												</svg>
											</span>
											<small className="ms-1">
												{company.industry}
											</small>
										</div>
									</Col>
								</Row>
							</div>
						</div>
					</div>
				</Card.Body>
			</Card>
		</Link>
	);
};

function EmptyQueryBoundary({ children, fallback }) {
	const { indexUiState } = useInstantSearch();
	// if (!indexUiState.query) return fallback;
	return children;
}

const Search = () => {
	const [selectedCompanies, setSelectedCompanies] = useState([]);
	const { user } = useUserAuth();

	useEffect(() => {
		// get the user's user document and if the user has a companies array, set the selectedCompanies state to the companies array

		// get the user's user document
		const getUserDoc = async () => {
			const userDocRef = doc(db, "users", user.uid);
			const userDocSnap = await getDoc(userDocRef);
			const userDoc = userDocSnap.data();

			// if the user has a companies array, set the selectedCompanies state to the companies array
			if (userDoc.companies) {
				setSelectedCompanies(userDoc.companies);
			}
		};

		getUserDoc();
	}, [user]);

	const handleButtonClick = async (cik) => {
		const userDocRef = doc(db, "users", user.uid);

		if (selectedCompanies.includes(cik)) {
			setSelectedCompanies(
				selectedCompanies.filter((companyCik) => companyCik !== cik)
			);

			// Update the user document
			try {
				await updateDoc(userDocRef, {
					companies: selectedCompanies.filter(
						(companyCik) => companyCik !== cik
					),
				});
			} catch (error) {
				console.error(error); // handle the error if needed
			}
		} else {
			setSelectedCompanies([...selectedCompanies, cik]);

			// Update the user document
			try {
				await updateDoc(userDocRef, {
					companies: [...selectedCompanies, cik],
				});
			} catch (error) {
				console.error(error); // handle the error if needed
			}
		}
	};

	return (
		<Container fluid>
			<InstantSearch indexName="companies" searchClient={searchClient}>
				<Configure analytics={false} hitsPerPage={10} />
				<Row className="mb-10">
					<Col
						md={{ span: 10, offset: 1 }}
						lg={{ span: 10, offset: 1 }}
						xl={{ span: 10, offset: 1 }}
					>
						<h1 className="fw-bold mb-1">Subscribe to companies</h1>
					</Col>
				</Row>
				<Row className="mb-10">
					<Col
						md={{ span: 3, offset: 1 }}
						lg={{ span: 3, offset: 1 }}
						xl={{ span: 3, offset: 1 }}
					>
						{/* <RefinementList attribute="exchange" /> */}
						<hr />
						<RefinementList attribute="industry" />
					</Col>
					<Col md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
						<SearchBox
							placeholder="Search for companies"
							classNames={{
								root: "",
								form: "input-group",
								input: "ps-3 form-control no-border",
								submitIcon: "d-none",
								reset: "d-none",
								loadingIndicator: "d-none",
							}}
						/>

						<EmptyQueryBoundary fallback={null}>
							<Hits
								className="mt-4"
								hitComponent={(props) => (
									<CompanyResults
										{...props}
										selectedCompanies={selectedCompanies}
										handleButtonClick={handleButtonClick}
									/>
								)}
							/>
						</EmptyQueryBoundary>
					</Col>
				</Row>
			</InstantSearch>
		</Container>
	);
};

export default Search;
